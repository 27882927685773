import { SxProps } from "@mui/material";

export const card: SxProps = {
  margin: "0 auto",
  width: "70vw",
  marginTop: "40px",
  marginBottom: "40px",
  paddingBottom: "30px",
  "@media screen and (min-width: 320px) and (max-width: 992px)": {
    width: "90vw",
  },
};

export const headerBox: SxProps = {
  borderBottom: "1px solid #E3E9F3",
  display: "flex",
  height: "74px",
  padding: "25px 16px",
  position: "relative",
  width: "fit-content",

  "&::after": {
    content: '""',
    width: "100%",
    height: "1px",
    position: "absolute",
    top: "72px",
    left: 0,
    right: 0,
    borderTop: "2px solid blue",
  },
};

export const text: SxProps = {
  width: "fit-content",
  color: "#375FE2",
  fontWeight: "700",
  height: "fit-content",
  fontSize: "14px",
  cursor: "pointer",
};

export const merchantBox = (loading: boolean) => {
  return {
    height: "calc(100% - 74px)",
    display: "flex",
    flexDirection: "row",
    alignItems: loading ? "center" : null,
    overflowX: "hidden",
    overflowY: "auto",
    "@media screen and (min-width: 320px) and (max-width: 478px)": {
      display: "flex",
      flexDirection: "column-reverse",
    },
    "@media screen and (min-width: 481px) and (max-width: 768px)": {
      display: "flex",
      flexDirection: "column-reverse",
    },
    "@media screen and (min-width: 768px) and (max-width: 1200px)": {
      display: "flex",
      flexDirection: "row",
    },
  };
};

export const merchantInfo: SxProps = {
  maxHeight: "100%",
  margin: "40px 65px",
  width: "calc(100% - 737px)",
  color: "#000",
  fontWeight: "400",
  fontSize: "14px",
  "@media screen and (min-width: 320px) and (max-width: 478px)": {
    margin: "40px 16px",
    width: "calc(100% - 32px)",
  },
  "@media screen and (min-width: 481px) and (max-width: 768px)": {
    margin: "40px 16px",
    width: "calc(100% - 32px)",
  },
  "@media screen and (min-width: 768px) and (max-width: 1200px)": {
    margin: "40px 16px",
    width: "calc(50%)",
  },
};

export const pageInfo: SxProps = {
  mb: "12px",
};
