import { SxProps } from "@mui/material";
import { black2, grey5 } from "../ThemeProvider/colors";
import { CSSProperties } from "react";

export const lefSideMenu: SxProps = {
  "&::-webkit-scrollbar": {
    width: "5px",
  },

  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
  },

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,.1)",
  },
};

const css: CSSProperties = {
  display: "flex",
  alignItems: "center",
  width: "100%",
  padding: "5px 25px",
  fontSize: "14px",
  lineHeight: "1.57",
  fontWeight: "500",
  color: black2,
  textDecoration: "none",
};

export const style: Record<string, CSSProperties> = {
  link: {
    ...css,
  },

  activeLink: {
    ...css,
    background: grey5,
  },
};
