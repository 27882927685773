"use client";

import { SvgIconProps, SvgIcon } from "@mui/material";
import React from "react";

const AppStoreIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => {
    return (
        <SvgIcon
            viewBox="0 0 18 22"
            sx={{
                height: "22px",
                width: "18px",
                ...sx,
            }}
            {...props}
        >
            <path
                d="M16.4946 7.7013C15.6264 8.31557 15.0367 8.93202 14.7246 9.5514C14.4125 10.1708 14.2561 10.842 14.2561 11.5636C14.2561 12.1348 14.3486 12.657 14.5337 13.1311C14.7188 13.6051 14.9807 14.055 15.3194 14.4808C15.658 14.9067 16.2241 15.3296 17.0183 15.7495C16.7113 16.6655 16.2349 17.5996 15.5899 18.5535C14.9441 19.5067 14.3594 20.1831 13.8356 20.5818C13.3119 20.9806 12.8118 21.18 12.3354 21.18C11.9544 21.18 11.4407 21.0566 10.7957 20.8083L10.4944 20.6951L10.0022 20.4847C9.65281 20.3773 9.26681 20.3233 8.84351 20.3233C8.4202 20.3233 7.923 20.4204 7.38274 20.614L7.00177 20.7593L6.52537 20.9536C6.14439 21.1048 5.76342 21.18 5.38244 21.18C4.57816 21.18 3.71577 20.6067 2.79454 19.4592C1.87403 18.3118 1.17808 17.0781 0.706706 15.7583C0.23533 14.4385 0 13.1055 0 11.7587C0 10.024 0.484291 8.5807 1.45287 7.4274C2.42145 6.27484 3.73585 5.69855 5.39751 5.69855C6.0009 5.69855 6.56698 5.81177 7.09647 6.03819L7.46166 6.18354L7.84264 6.34496C8.18128 6.48519 8.45105 6.55531 8.65194 6.55531C8.94826 6.55531 9.24457 6.49615 9.54089 6.37782L10.0489 6.18354L10.414 6.03819C11.0067 5.81177 11.6624 5.69855 12.3828 5.69855C14.0868 5.69855 15.4571 6.36687 16.4946 7.70276V7.7013ZM12.4624 0C12.4832 0.237379 12.494 0.419978 12.494 0.549259C12.494 1.21757 12.3031 1.90707 11.9222 2.61774C11.5412 3.32842 11.0433 3.88133 10.4298 4.27428C9.81568 4.66797 9.13839 4.86444 8.39796 4.86444C8.36639 4.60588 8.35061 4.41744 8.35061 4.29911C8.35061 3.72794 8.51706 3.12756 8.85068 2.49723C9.1843 1.86689 9.63631 1.33078 10.2081 0.888894C10.78 0.447004 11.425 0.167261 12.1446 0.0482064C12.2084 0.0379809 12.3139 0.0211815 12.4624 0Z"
                fill="#14234B"
            />
        </SvgIcon>
    );
};

export default AppStoreIcon;
