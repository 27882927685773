import {Box, IconButton, Drawer, useMediaQuery} from "@mui/material";
import React, {useState} from "react";
import CallNumberLogo from "../../Icons/CallNumberLogo";
import TezpayLogo from "../../Icons/TezpayLogo";
import {header, menuIcon, callNumberIcon, storeButtonsBox} from "./LayoutHeader.sx";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import DownloadButtons from "../../DownloadButtons/DownloadButtons";
import {Categories} from "../../../utils/services/type/categories.types";
import MenuList from "../../MenuList";
import LanguageSelector from "../../LanguageSelector";

interface Props {
    categoryStart: boolean;
    categoryEnd: boolean;
    categoryEndWithSlash: boolean;
    categories: Categories | undefined;
}

const LayoutHeader = (props: Props) => {
    const {categoryStart, categoryEnd, categoryEndWithSlash, categories} =
        props;
    const [open, setOpen] = useState(false);
    const isLargeScreen = useMediaQuery("(min-width: 993px)");
    const isMobile = useMediaQuery("(max-width: 478px)");
    const isTablet = useMediaQuery("(min-width: 577px)");
    const lang = localStorage.getItem("lang") || "";

    return (
        <Box sx={header}>
            {!((categoryStart && categoryEnd) || categoryEndWithSlash) && (
                <>
                    <IconButton
                        sx={
                            isMobile
                                ? menuIcon
                                : isTablet
                                    ? menuIcon
                                    : {...menuIcon, mr: "24.92px"}
                        }
                        onClick={() => setOpen(true)}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Drawer
                        anchor={"left"}
                        open={open}
                        onClose={() => setOpen(false)}
                        PaperProps={{sx: {width: "100vw"}}}
                    >
                        <Box onClick={() => setOpen(false)}>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    pr: "25px",
                                    pt: "10px",
                                }}
                            >
                                <IconButton
                                    onClick={() => setOpen(false)}
                                    sx={{
                                        border: "1px solid #EDEEF5",
                                        boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.06)",
                                        borderRadius: "12px",
                                    }}
                                >
                                    <CloseIcon/>
                                </IconButton>
                            </Box>
                            <MenuList categories={categories}/>
                        </Box>
                    </Drawer>
                </>
            )}

            <a href={`/${lang}/category`}>
                <TezpayLogo/>
            </a>
            {!isLargeScreen && <CallNumberLogo sx={callNumberIcon}/>}

            <Box sx={{display: "flex", alignItems: "center"}}>
                <Box sx={storeButtonsBox}>
                    <DownloadButtons/>
                </Box>
                {isLargeScreen && (
                    <CallNumberLogo sx={{...callNumberIcon, mr: "25px"}}/>
                )}
                {!isMobile && <LanguageSelector/>}
            </Box>
        </Box>
    );
};

export default LayoutHeader;
