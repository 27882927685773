import * as React from "react";
import useLanguageSelector from "./useLanguageSelector";
import { Button, MenuItem, Menu } from "@mui/material";
import DownArrowIcon from "../Icons/DownArrowIcon";

export default function LanguageSelector() {
  const {
    open,
    handleClick,
    handleClose,
    anchorEl,
    currentLang,
    handleChangeLang,
    isMobile,
  } = useLanguageSelector();

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant={"text"}
        sx={isMobile ? { minWidth: "30px", minHeight:"30px", borderRadius: "4px",
            backgroundColor:"#EDEEF5" } : { width: "95px", p: 0 }}
      >
        {currentLang}
      </Button>

      {currentLang === "Azərbaycan" || currentLang === "Az" ? (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={(e) => handleChangeLang(e)}>
            {isMobile ? "Ru" : "Русский"}
          </MenuItem>
          <MenuItem onClick={(e) => handleChangeLang(e)}>
            {isMobile ? "En" : "English"}
          </MenuItem>
        </Menu>
      ) : currentLang === "Русский" || currentLang === "Ru" ? (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={(e) => handleChangeLang(e)}>
            {isMobile ? "Az" : "Azərbaycan"}
          </MenuItem>
          <MenuItem onClick={(e) => handleChangeLang(e)}>
            {isMobile ? "En" : "English"}
          </MenuItem>
        </Menu>
      ) : (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={(e) => handleChangeLang(e)}>
            {isMobile ? "Ru" : "Русский"}
          </MenuItem>
          <MenuItem onClick={(e) => handleChangeLang(e)}>
            {isMobile ? "Az" : "Azərbaycan"}
          </MenuItem>
        </Menu>
      )}
    </>
  );
}
