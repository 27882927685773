import { Categories } from "./type/categories.types";
import { httpClient } from "../../api/http/http-client";
import { useQuery } from "react-query";
import { PostParamsTypes } from "./type/postParams.types";

export const categoryService = {
  getAllCategories: async (data: PostParamsTypes): Promise<Categories> => {
    return await httpClient.post<void, Categories>("/", data);
  },

  getCategory: async (data: PostParamsTypes): Promise<Categories> => {
    return await httpClient.post<void, Categories>("/", data);
  },
};

export const useGetCategoryList = (data: PostParamsTypes) => {
  const { isLoading: isCategoriesLoading, data: categories } = useQuery(
    ["categories", data],
    () => categoryService.getAllCategories(data)
  );

  return { isCategoriesLoading, categories };
};

export const useGetCategory = (data: PostParamsTypes) => {
  const { isLoading: isCategoryLoading, data: category } = useQuery(
    ["category", data],
    () => categoryService.getCategory(data)
  );

  return { isCategoryLoading, category };
};
