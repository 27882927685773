import { httpClient } from "../../api/http/http-client";
import { useQuery } from "react-query";
import { PostParamsTypes } from "./type/postParams.types";
import { MerchantsTypes } from "./type/merchants.types";

export const nextPageMerchantService = {
  getMerchant: async (
    data: PostParamsTypes,
    activityId: any
  ): Promise<MerchantsTypes> => {
    return await httpClient.post<void, MerchantsTypes>("/", data, {
      headers: {
        activityId: activityId,
      },
    });
  },
};

export const useGetNextPageMerchant = (
  data: PostParamsTypes,
  activityId: string | undefined
) => {
  const {
    isLoading: isNextPageMerchantLoading,
    data: nextPageMerchant,
    refetch: refetchNextPage,
  } = useQuery(
    ["next-page", data],
    () => nextPageMerchantService.getMerchant(data, activityId),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  return { isNextPageMerchantLoading, nextPageMerchant, refetchNextPage };
};
