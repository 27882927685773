import Bowser from "bowser";
import useWindowDimensions from "./useWindowDimensions";
import { v1 as uuidv1 } from "uuid";
import { useEffect } from "react";

const useDeviceInfo = () => {
  const browser = Bowser.parse(window.navigator.userAgent);
  const { width, height } = useWindowDimensions();
  const deviceId = uuidv1();

  useEffect(() => {
    if (!localStorage.getItem("deviceId")) {
      localStorage.setItem("deviceId", deviceId);
    }
  }, []);

  const deviceInfo = {
    appVersion: "1.0.0",
    brand: browser.browser.name,
    model: browser.browser.version,
    osType: browser.os.name,
    osVersion: browser.os.version,
    deviceId: localStorage.getItem("deviceId")
      ? localStorage.getItem("deviceId")
      : deviceId,
    displaySize: `${width}x${height}`,
  };

  return deviceInfo;
};

export default useDeviceInfo;
