import LoaderSpinner from "../LoaderSpinner";
import { Box, Button, FormGroup, TextField, Typography } from "@mui/material";
import React from "react";
import { Payment } from "../../utils/services/type/payment.types";
import { MerchantsTypes } from "../../utils/services/type/merchants.types";
import useLastPageMerchant from "./useLastPageMerchant";
import ErrorPage from "../ErrorPage";

interface Props {
  isNextPageMerchantLoading: boolean;
  complete: Payment | undefined;
  amount: number | undefined | null;
  setAmount: (
    value:
      | ((prevState: number | undefined | null) => number | undefined | null)
      | number
      | undefined
      | null
  ) => void;
  nextPageMerchant: undefined | MerchantsTypes;
  handleCompletePayment: (e: any) => void;
  handleBackButtonClick: () => void;
}

const LastPageMerchant = (props: Props) => {
  const {
    isNextPageMerchantLoading,
    nextPageMerchant,
    complete,
    // handleBackButtonClick, //! For next release
    setAmount,
    handleCompletePayment,
  } = props;

  const { error, formRef, handleChangeInputAmount, handleBlurInputAmount } =
    useLastPageMerchant({
      complete,
      setAmount,
    });

  if (isNextPageMerchantLoading) {
    return <LoaderSpinner spinner={true} />;
  }

  if (nextPageMerchant?.code !== 1000) {
    return <ErrorPage activityId={nextPageMerchant?.activityId} />;
  }

  return (
    <FormGroup>
      {nextPageMerchant?.data.widgetList.map(
        (item) =>
          item.widgetType === "readonlyTextbox" && (
            <Box key={item.id} sx={{ width: "100%" }}>
              <Typography sx={{ mb: "12px", mt: "12px" }}>
                {item.displayName}
              </Typography>
              <TextField disabled value={item.value} sx={{ width: "100%" }} />
            </Box>
          )
      )}

      <>
        <Typography sx={{ mb: "12px", mt: "12px" }}>
          {nextPageMerchant?.data.uiDisplayName?.amount}
        </Typography>
        <form
          onSubmit={(e: any) => {
            if (!error) {
              handleCompletePayment(e);
            }
          }}
        >
          <Box sx={{ height: "73px" }}>
            <TextField
              type={"number"}
              sx={{ mb: "6px", width: "100%" }}
              onChange={(e) =>
                handleChangeInputAmount(
                  e,
                  nextPageMerchant.data?.minAmount,
                  nextPageMerchant.data?.maxAmount
                )
              }
              onBlur={() =>
                handleBlurInputAmount(
                  nextPageMerchant.data?.minAmount,
                  nextPageMerchant.data?.maxAmount
                )
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleBlurInputAmount(
                    nextPageMerchant.data?.minAmount,
                    nextPageMerchant.data?.maxAmount
                  );
                }
              }}
              error={error}
              required
            />
            {nextPageMerchant.data?.minAmount !== undefined &&
              nextPageMerchant.data?.maxAmount !== undefined && (
                <Typography
                  sx={{ color: "#000000", fontSize: "10px", pl: "5px" }}
                >
                  Məbləğ {nextPageMerchant.data?.minAmount} ilə{" "}
                  {nextPageMerchant.data?.maxAmount} arasında olmalıdır
                </Typography>
              )}
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: "12px" }}>
            {/*<Button variant="text" onClick={handleBackButtonClick}>*/}
            {/*  {nextPageMerchant?.data.uiDisplayName?.backwardAction}*/}
            {/*</Button>  //! For next release  */}
            <Button variant="outlined" type={"submit"} disabled={error}>
              {nextPageMerchant?.data.uiDisplayName?.forwardAction}
            </Button>
          </Box>
        </form>
      </>

      {complete !== undefined && (
        <form
          action={complete?.mpi?.formSubmitUrl}
          method="POST"
          name="payment"
          ref={formRef}
        >
          <input type="hidden" name="AMOUNT" value={complete?.mpi?.amount} />
          <input
            type="hidden"
            name="CURRENCY"
            value={complete?.mpi?.currency}
          />
          <input type="hidden" name="ORDER" value={complete?.mpi?.order} />

          <input type="hidden" name="DESC" value={complete?.mpi?.desc} />
          <input
            type="hidden"
            name="MERCH_NAME"
            value={complete?.mpi?.merchName}
          />
          <input
            type="hidden"
            name="MERCH_URL"
            value={complete?.mpi?.merchUrl}
          />
          <input
            type="hidden"
            name="TERMINAL"
            value={complete?.mpi?.terminal}
          />
          <input type="hidden" name={complete?.mpi?.email} />
          <input type="hidden" name="TRTYPE" value={complete?.mpi?.trType} />
          <input type="hidden" name="COUNTRY" value={complete?.mpi?.country} />
          <input
            type="hidden"
            name="MERCH_GMT"
            value={complete?.mpi?.merchGmt}
          />
          <input
            type="hidden"
            name="TIMESTAMP"
            value={complete?.mpi?.timestamp}
          />
          <input type="hidden" name="NONCE" value={complete?.mpi?.nonce} />
          <input type="hidden" name="BACKREF" value={complete?.mpi?.backref} />

          <input type="hidden" name="P_SIGN" value={complete?.mpi?.psign} />
          <input type="hidden" alt="submit" />
        </form>
      )}
    </FormGroup>
  );
};

export default LastPageMerchant;
