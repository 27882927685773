import { useEffect, useRef, useState } from "react";
import { Payment } from "../../utils/services/type/payment.types";

interface Props {
  complete: Payment | undefined;
  setAmount: (
    value:
      | ((prevState: number | undefined | null) => number | undefined | null)
      | number
      | undefined
      | null
  ) => void;
}

const useLastPageMerchant = (props: Props) => {
  const { complete, setAmount } = props;
  const [error, setError] = useState<boolean>(false);
  const [price, setPrice] = useState<any>();
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.submit();
    }
  }, [complete]);

  const handleChangeInputAmount = (
    e: any,
    minAmount?: number,
    maxAmount?: number
  ) => {
    const price = e.target.value;
    if (minAmount !== undefined && price < minAmount) {
      setError(true);
    } else if (maxAmount !== undefined && price > maxAmount) {
      setError(true);
    } else {
      setError(false);
    }
    setPrice(price);
  };

  const handleBlurInputAmount = (minAmount?: number, maxAmount?: number) => {
    let regexp = new RegExp("^\\d{1,6}([\\.,\\,]{1}\\d{1,2})?$");

    if (regexp.test(price)) {
      if (minAmount !== undefined && price < minAmount) {
        setError(true);
        setAmount(null);
      } else if (maxAmount !== undefined && price > maxAmount) {
        setError(true);
        setAmount(null);
      } else {
        setAmount(price);
      }
    } else {
      setError(true);
      setAmount(null);
    }
  };

  return { error, formRef, handleChangeInputAmount, handleBlurInputAmount };
};

export default useLastPageMerchant;
