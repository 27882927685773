import { Box, Typography } from "@mui/material";

interface Props {
  activityId: string | undefined;
}

const ErrorPage = (props: Props) => {
  const { activityId } = props;

  return (
    <Box>
      <Typography>Xəta baş verdi.</Typography>
      <Typography>{activityId}</Typography>
      <Typography>
        referans kodu təqdim edərək sistem administratorla əlaqə saxlayın.
      </Typography>
    </Box>
  );
};

export default ErrorPage;
