import { SxProps } from "@mui/material";

export const card: SxProps = {
  margin: "0 auto",
  width: "70vw",
  marginTop: "40px",
  marginBottom: "40px",

  "@media screen and (min-width: 320px) and (max-width: 992px)": {
    width: "90vw",
    overflow: "auto",
  },
};

export const text: SxProps = {
  borderBottom: "2px solid #375FE2",
  padding: "25px 16px",
  width: "fit-content",
  color: "#375FE2",
  fontWeight: "700",
  fontSize: "14px",
  cursor: "pointer",
};

export const categoryBox = (length: boolean): SxProps => {
  return {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(205px, max-content))",
    justifyContent: length ? "center" : "flex-start",
    padding: "initial",
    overflow: "auto",
    height: "calc(100% - 74px)",
    gridAutoRows: "285px",

    "@media screen and (min-width: 320px) and (max-width: 992px)": {
      justifyContent: "center",
    },
  };
};

export const iconBox: SxProps = {
  margin: "32px 1.5rem",
  width: "160px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

export const iconButton: SxProps = {
  border: "1px solid #EDEEF5",
  boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.06)",
  borderRadius: "12px",
  marginBottom: "13px",
  width: "160px",
  height: "160px",

  "&:hover": {
    background: "transparent",
    borderColor: "#375FE2",
  },

  img: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    objectPosition: "center",
  },
};

export const infoBox: SxProps = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  padding: "16px 0 0 16px",
  boxSizing: "border-box",
  width: "295px",
};

export const infoText: SxProps = {
  marginBottom: "12px",
};

export const categoryText: SxProps = {
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: "2",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textAlign: "center",
};
