import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";

const useCategory = () => {
  const navigate = useNavigate();
  const lang = localStorage.getItem("lang") || "";

  const navigateToCategory = (url: string) => {
    navigate(
      `${ROUTES.subcategory
        .replace(":lang", lang)
        .replace(":subCategory", url)}`
    );
  };

  return {
    navigateToCategory,
  };
};

export default useCategory;
