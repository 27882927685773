import { useEffect, useState } from "react";
import { WidgetList } from "../../utils/services/type/widgetList.types";

interface Props {
  data: WidgetList;
  handleUpdatedState: (
    widgetId: string | undefined,
    value: string | undefined,
    flag?: boolean,
    defaultValue?: { widgetId: string; value: string }[]
  ) => void;
}

let arr: { widgetId: string; value: string }[] = [];

export const useSelectableMerchant = (props: Props) => {
  const { data, handleUpdatedState } = props;
  const [label, setLabel] = useState<number>(0);
  const [prefix, setPrefix] = useState<number>(0);

  const handleChangeLabel = (e: any) => {
    setLabel(e.target.value);
  };

  const handleChangePrefix = (e: any) => {
    setPrefix(e.target.value);
  };

  const functionDefaultValue = (defaultValue = 0, checkInit = true) => {
    if (checkInit) {
      if (label === 0) {
        !arr.map(({ widgetId }) => widgetId).includes(data.id) &&
          arr.push({
            widgetId: data.id,
            value: data.selectableWidgetList[0].id,
          });
      }

      if (label === 0 && prefix === 0) {
        data.selectableWidgetList[defaultValue].widgetList.forEach((item) => {
          if (item.widgetType === "combobox") {
            !arr.map(({ widgetId }) => widgetId).includes(item.id) &&
              arr.push({
                widgetId: item.id,
                value: item.selectableWidgetList[defaultValue].id,
              });
          }
        });
      }
    } else {
      arr = [];
      !arr.map(({ widgetId }) => widgetId).includes(data.id) &&
        arr.push({
          widgetId: data.id,
          value: data.selectableWidgetList[defaultValue].id,
        });
      data.selectableWidgetList[defaultValue].widgetList.forEach((item) => {
        if (item.widgetType === "combobox") {
          !arr.map(({ widgetId }) => widgetId).includes(item.id) &&
            arr.push({
              widgetId: item.id,
              value: item.selectableWidgetList[defaultValue].id,
            });
        }
      });
    }

    handleUpdatedState(undefined, undefined, undefined, arr);
  };

  useEffect(() => {
    functionDefaultValue();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    label,
    prefix,
    functionDefaultValue,
    setPrefix,
    handleChangeLabel,
    handleChangePrefix,
  };
};
