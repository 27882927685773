import {
  FormControl,
  FormGroup,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import { pageInfo } from "../../pages/Merchant/Merchant.sx";
import React, { useState } from "react";
import { MerchantsTypes } from "../../utils/services/type/merchants.types";
import { WidgetList } from "../../utils/services/type/widgetList.types";

interface Props {
  merchant: MerchantsTypes | undefined;
  data: WidgetList;
  type: string;
  handleChangeInput: (
    id: string,
    e: any,
    flag?: boolean,
    defaultValue?: undefined,
    regex?: string
  ) => void;
  regEx?: string;
  errorType?: boolean;
}

const TextboxMerchant = (props: Props) => {
  const { data, type, regEx, errorType, handleChangeInput } = props;
  const [value, setValue] = useState<string>("");
  const [error, setError] = useState<string>();
  console.log(data);

  const handleUserInput = (): void => {
    if (regEx) {
      let regexp = new RegExp(regEx);

      if (value !== undefined && !regexp.test(value)) {
        setError("Daxil edilən məlumat yanlışdır");
      }

      if (value !== undefined && regexp.test(value)) {
        setError("");
      }

      if (value !== undefined) {
        handleChangeInput(data?.id, value, undefined, undefined, regEx);
      }
    } else {
      handleChangeInput(data?.id, value);
    }
  };

  return (
    <Box sx={{ mb: "5px" }}>
      <Typography sx={pageInfo}>{data?.displayName}</Typography>
      <FormGroup sx={{ height: "73px" }}>
        <FormControl>
          <TextField
            type={type}
            onChange={(e) => {
              setError("");
              setValue(e.target.value);
            }}
            onBlur={(e) => handleUserInput()}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleUserInput();
              }
            }}
            required
          />
          {error !== undefined && error.length > 0 && errorType && (
            <Typography
              sx={{ color: "#ff0000", fontSize: "14px", pl: "5px", mt: "5px" }}
            >
              {error}
            </Typography>
          )}
        </FormControl>
      </FormGroup>
    </Box>
  );
};

export default TextboxMerchant;
