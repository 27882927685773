import ChoiceListMerchant from "../ChoiceListMerchant";
import React, { FormEvent } from "react";
import { MerchantsTypes } from "../../utils/services/type/merchants.types";
import { Box, Button, TextField, Typography } from "@mui/material";
import LoaderSpinner from "../LoaderSpinner";
import ErrorPage from "../ErrorPage";
import useNextPageMerchant from "./useNextPageMerchant";
import LastPageMerchant from "../LastPageMerchant";
import { Payment } from "../../utils/services/type/payment.types";
import DatePickerMerchant from "../DatePickerMerchant";
import { nextPageButton } from "./NextPage.sx";

interface Props {
  isNextPageInMerchantLoading: boolean;
  nextPageInMerchant: undefined | MerchantsTypes;
  complete: Payment | undefined;
  amount: number | undefined | null;
  setAmount: (
    value:
      | ((prevState: number | undefined | null) => number | undefined | null)
      | number
      | undefined
      | null
  ) => void;
  handleCompletePayment: (e: FormEvent<HTMLFormElement>) => void;
  handleBackButtonClick: () => void;
}

const NextPageMerchant = (props: Props) => {
  const {
    isNextPageInMerchantLoading,
    nextPageInMerchant,
    complete,
    amount,
    setAmount,
    handleCompletePayment,
    handleBackButtonClick,
  } = props;
  const {
    isNextPageMerchantLoading,
    nextPageMerchant,
    checkForLast,
    handleClickButton,
    handleChangeChoice,
  } = useNextPageMerchant({
    nextPageInMerchant,
  });

  if (isNextPageInMerchantLoading) {
    return <LoaderSpinner spinner={true} />;
  }

  if (nextPageInMerchant?.code !== 1000) {
    return <ErrorPage activityId={nextPageInMerchant?.activityId} />;
  }
  return (
    <>
      {!checkForLast && (
        <>
          {nextPageInMerchant?.data.widgetList.map((item) =>
            item.widgetType === "readonlyTextbox" ? (
              <Box key={item.id} sx={{ width: "100%" }}>
                <Typography sx={{ mb: "12px", mt: "12px" }}>
                  {item.displayName}
                </Typography>
                <TextField disabled value={item.value} sx={{ width: "100%" }} />
              </Box>
            ) : (
              item.widgetType === "choiceList" && (
                <ChoiceListMerchant
                  key={item.id}
                  data={item}
                  handleUpdatedState={handleChangeChoice}
                />
              )
            )
          )}
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: "12px" }}>
            {/*<Button variant="text" onClick={handleBackButtonClick}>*/}
            {/*  {nextPageMerchant?.data.uiDisplayName?.backwardAction}*/}
            {/*</Button>  //! For next release  */}
            <Button
              sx={nextPageButton}
              variant="outlined"
              onClick={handleClickButton}
            >
              {nextPageInMerchant?.data.uiDisplayName?.forwardAction}
            </Button>
          </Box>
        </>
      )}

      {checkForLast && !nextPageMerchant?.data.nextStepId && (
        <LastPageMerchant
          isNextPageMerchantLoading={isNextPageMerchantLoading}
          complete={complete}
          amount={amount}
          setAmount={setAmount}
          nextPageMerchant={nextPageMerchant}
          handleCompletePayment={handleCompletePayment}
          handleBackButtonClick={handleBackButtonClick}
        />
      )}
    </>
  );
};

export default NextPageMerchant;
