import {
  FormControl,
  FormGroup,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import { pageInfo } from "../../pages/Merchant/Merchant.sx";
import React, { useState } from "react";

import { MerchantsTypes } from "../../utils/services/type/merchants.types";
import { DateTypes } from "../../utils/services/type/date.types";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

interface Props {
  data: DateTypes;
  handleChangeInput: (id: string, e: any) => void;
  errorType?: boolean;
}
function returnDate(d: string, regex?: string) {
  return dayjs(d).format(regex ? regex : "DD.MM.YYYY");
}
const DatePickerMerchant = (props: Props) => {
  const { data, errorType, handleChangeInput } = props;
  const [error, setError] = useState<string>();
  return (
    <Box sx={{ mb: "5px" }}>
      <Typography sx={pageInfo}>{data.displayName}</Typography>
      <FormGroup sx={{ height: "73px" }}>
        <FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format={data.regex}
              onChange={(e: any) => {
                const d = new Date(e).toLocaleDateString("en-US", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                });
                handleChangeInput(data.id, returnDate(d, data.regex));
              }}
            />
            {error !== undefined && error.length > 0 && errorType && (
              <Typography
                sx={{
                  color: "#ff0000",
                  fontSize: "14px",
                  pl: "5px",
                  mt: "5px",
                }}
              >
                {error}
              </Typography>
            )}
          </LocalizationProvider>
        </FormControl>
      </FormGroup>
    </Box>
  );
};

export default DatePickerMerchant;
