export const white = "#FFFFFF";
export const black1 = "#000000";
export const black2 = "#2D2C2C";

export const blue1 = "#013DFF";
export const blue5 = "#3766FF";
//export const blue5 = "transparent";
//export const trans = "transparent";

export const red = "#BB0000";

export const grey1 = "#F2F2F2";
export const grey2 = "#F6F6F7";
export const grey3 = "#EDEFF1";
export const grey4 = "#A0A1A9";
export const grey5 = "#EDEFF5";
export const grey8 = "#FBFBFB";
export const grey13 = "#E7E9EB";
