import { useGetNextPageMerchant } from "../../utils/services/nextPageMerchant";
import { MerchantsTypes } from "../../utils/services/type/merchants.types";
import { useEffect, useState } from "react";
import useDeviceInfo from "../../constants/useDeviceInfo";

interface Props {
  nextPageInMerchant: undefined | MerchantsTypes;
}

const useNextPageMerchant = (props: Props) => {
  const { nextPageInMerchant } = props;
  const [dataToSend, setDataToSend] = useState<
    { widgetId: string | undefined; value: string | undefined }[]
  >([]);
  const [checkForLast, setCheckForLast] = useState(false);
  const deviceInfo = useDeviceInfo();

  const lang = localStorage.getItem("lang") || "";
  const { isNextPageMerchantLoading, nextPageMerchant, refetchNextPage } =
    useGetNextPageMerchant(
      {
        action: "mob.tezpay.processStep",
        stepId: nextPageInMerchant?.data.stepId,
        sourceId: "52ebbdee-3981-4a58-a00e-fa5bbd5253a7",
        lang: lang,
        widgetList: dataToSend,
        deviceInfo: deviceInfo,
      },
      nextPageInMerchant?.activityId
    );

  useEffect(() => {
    nextPageInMerchant?.data.widgetList.map((item) => {
      if (item.widgetType === "choiceList") {
        setDataToSend([{ widgetId: item.id, value: item.choiceList[0].key }]);
      }
    });
  }, []);

  const handleChangeChoice = (value: string) => {
    setDataToSend([{ widgetId: dataToSend[0].widgetId, value: value }]);
  };

  const handleClickButton = () => {
    setCheckForLast(true);
    refetchNextPage();
  };

  return {
    isNextPageMerchantLoading,
    nextPageMerchant,
    checkForLast,
    handleChangeChoice,
    handleClickButton,
  };
};

export default useNextPageMerchant;
