import { useState } from "react";

const useChoiceListMerchant = () => {
  const [label, setLabel] = useState(0);

  const handleChangeLabel = (e: any) => {
    setLabel(e.target.value);
  };

  return {
    label,
    handleChangeLabel,
  };
};

export default useChoiceListMerchant;
