import { ClipLoader } from "react-spinners";
import { Box } from "@mui/material";
import React from "react";
import { loaderBox } from "./LoaderSpinner.sx";

interface Props {
  spinner: boolean;
}

const LoaderSpinner = (props: Props) => {
  const { spinner } = props;

  return (
    <Box sx={loaderBox(spinner)}>
      <ClipLoader
        color={"#375FE2"}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </Box>
  );
};

export default LoaderSpinner;
