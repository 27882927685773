import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";
import { ColorMode, getTheme } from "./theme";

export const ColorModeContext = React.createContext({
  mode: "system" as ColorMode,
  setMode: (_mode: ColorMode) => {
    return;
  },
});

export interface ThemeProviderProps {
  children?: React.ReactNode;
  mode?: ColorMode;
}

/**
 * ThemeProvider
 */
export const ThemeProvider = (props: ThemeProviderProps) => {
  const { children } = props;

  const [mode, setMode] = React.useState<ColorMode>(props.mode || "system");

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = React.useMemo(() => {
    let res = mode;

    if (mode === "system") {
      res = prefersDarkMode ? "dark" : "light";
    }

    return getTheme(res);
  }, [mode, prefersDarkMode]);

  const colorMode = React.useMemo(
    () => ({
      mode,
      setMode: (newMode: ColorMode) => {
        setMode(newMode);
      },
    }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ColorModeContext.Provider>
  );
};
