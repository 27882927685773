import { SxProps } from "@mui/material";

export const hintMerchantSx: SxProps = {

    marginTop:"80px",
    marginLeft:"100px",
    display:"flex",
    flexDirection: "row",
    color: "black",
    backgroundColor: "#FFF7E6",
    width:"350px",
    height:"124px",
    borderRadius:"8px",
    padding:"25px",
    fontSize: "14px",
    lineHeight:"22px",
    fontWeight: "400",

    "@media screen and (min-width: 320px) and (max-width: 480px)": {
        marginLeft:"12px",
        marginTop:"20px",
        width:"calc(90%)",
    },

    "@media screen and (min-width: 481px) and (max-width: 768px)": {
        marginLeft:"12px",
        marginTop:"20px",
        width: "calc(100% - 36px)",
    },
    "@media screen and (min-width: 768px) and (max-width: 1200px)": {
        marginTop:"40px",
        marginLeft:"40px",
        width:"270px",
    },
};