"use client";

import { Box, Button } from "@mui/material";
import GooglePlayIcon from "../Icons/GooglePlayIcon";
import AppStoreIcon from "../Icons/AppStoreIcon";
import React from "react";
// import Image from "next/image";

import googleImage from "../../../img_12.png";
import {blue5} from "../ThemeProvider/colors";

const DownloadButtons = () => {
    return (
        <>
            <Button
                sx={{
                    boxSizing: "border-box",
                    background: "#fff",
                    color: "#14234b",
                    border: "1px solid #14234b",
                    fontSize: "12px",
                    borderRadius: "8px",
                    padding: "12px 16px",
                    display: "flex",
                    alignItems: "center",
                    "&:hover": {
                        backgroundColor: "rgb(237, 239, 245)",
                    },
                }}
            >
                {/*<GooglePlayIcon sx={{ mr: "5px" }} /> */}
                <a target="_blank" style={{ color: '#14234b', textDecoration: "none", display: "flex", flexDirection: "row", alignItems: "center"}} href='https://play.google.com/store/apps/details?id=az.tezpay.app'>
                    <img
                        src={googleImage}
                        alt={"logo"}
                        style={{ width: "18px", height: "20px", marginRight: "5px" }}
                    />
                    <span>Google Play</span>
                </a>
            </Button>
            <Button
                sx={{
                    boxSizing: "border-box",
                    background: "#fff",
                    color: "#14234b",
                    border: "1px solid #14234b",
                    fontSize: "12px",
                    borderRadius: "8px",
                    padding: "12px 16px",
                    display: "flex",
                    alignItems: "center",
                    "&:hover": {
                        backgroundColor: "rgb(237, 239, 245)",
                    },
                }}
            >
                <a target="_blank" style={{ color: '#14234b', textDecoration: "none", display: "flex", flexDirection: "row", alignItems: "center"}} href='https://apps.apple.com/ua/app/tezpay/id1631128193'>
                    <AppStoreIcon sx={{ mr: "5px" }} />
                    App Store
                </a>
            </Button>
        </>
    );
};

export default DownloadButtons;
