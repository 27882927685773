import { SxProps } from "@mui/material";
import { white } from "../../ThemeProvider/colors";

export const mobileheader: SxProps = {
    background: white,
    display: "flex",
    justifyContent: "space-around",
    flexDirection:"row",
    textAlign:"center",
    alignItems:"center",

    "@media screen and (min-width: 320px) and (max-width: 576px)": {
        width: "100%",
        height: "70px",
        padding: "25px 0",
        marginTop: "20px",
    },
    "@media screen and (min-width: 577px) and (max-width: 768px)": {
        display: "none",
    },

    "@media screen and (min-width: 768px) and (max-width: 992px)": {
        display: "none",
    },
};

export const menuIcon: SxProps = {
    display: "none",

    "@media screen and (min-width: 320px) and (max-width: 992px)": {
        display: "block",

        "&:hover": {
            background: "transparent",
        },
    },
};

export const storeButtonsBox: SxProps = {
    display: "flex",
    gap: "10px",
    marginRight: "30px",
    "&:hover":{
        backgroundcolor: 'transparent'
    },
    "@media screen and (min-width: 320px) and (max-width: 576px)": {
        display: "none",
    },
};

export const callNumberIcon: SxProps = {
    display: "block",

    "@media screen and (min-width: 320px) and (max-width: 576px)": {
        display: "none",
    },
};

