import { SxProps } from "@mui/material";

export const card: SxProps = {
  margin: "0 auto",
  width: "70vw",
  marginTop: "40px",
  marginBottom: "40px",

  "@media screen and (min-width: 320px) and (max-width: 576px)": {
    width: "90vw",
    overflow: "auto",
  },
};

export const text: SxProps = {
  borderBottom: "2px solid #375FE2",
  padding: "25px 16px",
  width: "fit-content",
  color: "#375FE2",
  fontWeight: "700",
  fontSize: "14px",
};

export const categoryBox: SxProps = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(154px, max-content))",
  gridAutoRows: "210px",
  justifyContent: "center",
  padding: "initial",
  overflow: "auto",
  height: "calc(100% - 74px)",
};

export const iconBox: SxProps = {
  margin: "32px 1.5rem",
  width: "106px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

export const iconButton: SxProps = {
  border: "1px solid #EDEEF5",
  boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.06)",
  padding: "25px 30px",
  borderRadius: "12px",
  maxHeight: "85px",
  minHeight: "85px",
  maxWidth: "85px",
  marginBottom: "13px",

  "&:hover": {
    background: "transparent",
    borderColor: "#375FE2",
  },
};

export const categoryText: SxProps = {
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: "2",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textAlign: "center",
};
