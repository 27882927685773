import { httpClient } from "../../api/http/http-client";
import { useQuery } from "react-query";
import { PostParamsTypes } from "./type/postParams.types";
import { Payment } from "./type/payment.types";

export const completePaymentService = {
  getCompletePayment: async (
    data: PostParamsTypes,
    activityId: any
  ): Promise<Payment> => {
    return await httpClient.post<void, Payment>("/", data, {
      headers: {
        activityId: activityId,
      },
    });
  },
};

export const useCompletePayment = (
  data: PostParamsTypes,
  activityId: string | undefined
) => {
  const {
    isLoading: isCompletetLoading,
    data: complete,
    refetch: refetchComplete,
  } = useQuery(
    ["complete", data],
    () => completePaymentService.getCompletePayment(data, activityId),
    { refetchOnWindowFocus: false, enabled: false }
  );

  return { isCompletetLoading, complete, refetchComplete };
};
