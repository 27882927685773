import { SvgIconProps, SvgIcon } from "@mui/material";
import React from "react";

const TezpayLogo = ({ sx, ...props }: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      viewBox="0 0 178 43"
      sx={{
        height: "43px",
        width: "178px",
        ...sx,
      }}
      {...props}
    >
      <path
        d="M55.1864 15.435H48.8864L46.8634 24.944C46.2054 28.044 47.5444 29.429 49.7324 29.429C51.189 29.3934 52.6057 28.9457 53.8184 28.138L55.8324 31.938C53.8741 33.318 51.536 34.0567 49.1404 34.052C43.4824 34.052 40.6654 30.694 41.9104 24.847L43.9104 15.436L44.8734 10.975L46.3294 4.16602H51.3064L49.8494 10.972H58.2274L55.1864 15.435Z"
        fill="#182238"
      />
      <path
        d="M61.7903 20.319H73.5293C73.5657 20.0701 73.5814 19.8186 73.5763 19.567C73.6349 18.9203 73.5507 18.2686 73.3295 17.658C73.1084 17.0474 72.7557 16.4929 72.2965 16.0337C71.8374 15.5745 71.2829 15.2219 70.6723 15.0008C70.0617 14.7796 69.41 14.6954 68.7633 14.754C64.6073 14.754 62.6363 17.525 61.7903 20.319ZM75.7133 31.308C73.1574 33.2165 70.0259 34.1938 66.8383 34.078C60.0293 34.078 56.2493 29.851 56.2493 24.217C56.2493 18.394 60.0793 10.387 69.0213 10.387C74.6803 10.387 78.4603 13.815 78.4603 19.427C78.4499 20.9981 78.1636 22.5551 77.6143 24.027H61.2503C61.2733 27.642 63.4573 29.779 67.3773 29.779C69.6326 29.7759 71.825 29.0345 73.6193 27.668L75.7133 31.308Z"
        fill="#182238"
      />
      <path
        d="M100.115 14.708L85.6754 29.008H97.6754L96.7363 33.469H78.0684L78.8683 29.619L93.5184 15.436H81.5184L82.4813 10.975H100.935L100.115 14.708Z"
        fill="#182238"
      />
      <path
        d="M105.687 22.222C105.194 26.329 107.47 29.429 111.462 29.429C115.83 29.429 119.117 25.629 119.117 21.118C119.117 17.69 117.027 15.018 113.247 15.018C109.255 15.013 106.179 18.112 105.687 22.222ZM104.187 30.814L101.651 42.864H96.6714L103.459 10.973H108.366L107.916 12.991C108.779 12.1332 109.806 11.4583 110.936 11.0066C112.066 10.5548 113.275 10.3356 114.491 10.362C120.008 10.362 124.191 14.706 124.191 20.576C124.191 27.714 118.743 34.054 111.419 34.054C107.777 34.055 105.499 32.787 104.185 30.814H104.187Z"
        fill="#182238"
      />
      <path
        d="M143.834 22.2211C144.327 18.1111 142.051 15.0131 138.058 15.0131C133.697 15.0131 130.404 18.8131 130.404 23.3241C130.404 26.7521 132.494 29.4241 136.274 29.4241C140.266 29.4281 143.341 26.3291 143.834 22.2211ZM145.334 13.6501L145.919 10.9741H150.826L146.036 33.4681H141.151L141.573 31.4681C140.714 32.3221 139.69 32.9933 138.565 33.4412C137.439 33.889 136.234 34.1043 135.022 34.0741C129.505 34.0741 125.323 29.7301 125.323 23.8601C125.323 16.7231 130.77 10.3831 138.094 10.3831C141.745 10.3871 144.024 11.6781 145.338 13.6511L145.334 13.6501Z"
        fill="#182238"
      />
      <path
        d="M154.612 43H148.977L159.449 28.4L152.171 10.979H157.384L162.596 23.963L171.496 10.979H177.084L154.612 43Z"
        fill="#182238"
      />
      <path
        d="M23.4574 10.014C25.6213 12.4761 27.0663 15.4859 27.6344 18.714C28.249 22.5045 27.5264 26.3908 25.5904 29.707C23.6474 32.9784 20.6538 35.4955 17.0974 36.848C18.8714 37.086 20.6726 37.0275 22.4274 36.675C24.5999 36.2555 26.6738 35.4301 28.5404 34.242C31.4972 32.4677 33.8364 29.8265 35.2404 26.677C36.0844 24.877 36.9924 18.932 36.9924 18.932L23.4574 10.014Z"
        fill="url(#paint0_linear_15_245)"
      />
      <path
        d="M7.80827 4.61603C10.8694 2.13267 14.6817 0.760018 18.6233 0.722026C21.6431 0.6598 24.6314 1.34565 27.3219 2.71846C30.0124 4.09127 32.3214 6.10838 34.0433 8.59003C35.4526 10.6942 36.4121 13.0669 36.8616 15.5593C37.311 18.0517 37.2408 20.6101 36.6553 23.074C36.0088 25.4585 34.9351 27.7059 33.4863 29.707C33.4863 29.707 35.1863 26.832 32.4083 21.084C29.6983 15.461 26.6423 13.016 22.3813 8.99703C15.8263 2.81603 6.72827 5.57403 6.72827 5.57403L7.80827 4.61603Z"
        fill="url(#paint1_linear_15_245)"
      />
      <path
        d="M13.8695 15.447C15.4241 17.1712 16.4636 19.2972 16.8695 21.583C17.3025 24.2547 16.7938 26.9939 15.4305 29.332C14.0598 31.6392 11.9481 33.414 9.43945 34.367C10.6906 34.5342 11.9609 34.4926 13.1984 34.244C14.7302 33.9489 16.1922 33.3667 17.5075 32.528C19.5935 31.2782 21.2434 29.4156 22.2325 27.194C22.7954 25.4116 23.2085 23.5852 23.4675 21.734L13.8695 15.447Z"
        fill="url(#paint2_linear_15_245)"
      />
      <path
        d="M2.89139 11.636C5.05001 9.8863 7.73784 8.91975 10.5164 8.894C12.476 8.83756 14.4221 9.23502 16.2026 10.0553C17.9831 10.8756 19.5498 12.0966 20.7802 13.6227C22.0107 15.1489 22.8715 16.9389 23.2955 18.8529C23.7195 20.7669 23.6952 22.753 23.2244 24.656C22.7722 26.3327 22.0274 27.9164 21.0244 29.334C20.7145 26.5866 19.8811 23.9239 18.5694 21.49C17.1389 18.8279 15.1968 16.4745 12.8544 14.565C11.4027 13.3138 9.65752 12.4511 7.7819 12.0577C5.90627 11.6642 3.9615 11.7527 2.12939 12.315L2.89139 11.636Z"
        fill="url(#paint3_linear_15_245)"
      />
      <path
        d="M7.40226 19.788C8.26857 20.7482 8.84758 21.9326 9.07326 23.206C9.31513 24.6939 9.03233 26.2196 8.27326 27.522C7.5115 28.8089 6.33527 29.799 4.93726 30.33C5.63427 30.423 6.34187 30.3996 7.03126 30.261C7.88443 30.0967 8.69876 29.7723 9.43126 29.305C10.5937 28.6092 11.5132 27.5717 12.0643 26.334C12.3775 25.3408 12.608 24.3233 12.7533 23.292L7.40226 19.788Z"
        fill="url(#paint4_linear_15_245)"
      />
      <path
        d="M1.28535 17.666C2.48752 16.6918 3.98407 16.1533 5.53135 16.138C6.71772 16.1143 7.89157 16.3842 8.94847 16.9236C10.0054 17.4631 10.9126 18.2554 11.5894 19.23C12.1429 20.0567 12.5197 20.9888 12.6962 21.9679C12.8728 22.9471 12.8453 23.9521 12.6154 24.92C12.364 25.8539 11.9492 26.7358 11.3904 27.525C11.2202 25.9936 10.755 24.5095 10.0204 23.155C9.22304 21.6719 8.14067 20.3608 6.83535 19.297C6.02635 18.6004 5.05414 18.12 4.00936 17.9006C2.96458 17.6811 1.88125 17.7298 0.860352 18.042L1.28535 17.666Z"
        fill="url(#paint5_linear_15_245)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_15_245"
          x1="41.9463"
          y1="21.5064"
          x2="48.1346"
          y2="32.5074"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#294FC9" />
          <stop offset="0.552" stopColor="#496EE5" />
          <stop offset="1" stopColor="#6589FE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_15_245"
          x1="27.1132"
          y1="5.79119"
          x2="31.9176"
          y2="19.3648"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#294FC9" />
          <stop offset="0.552" stopColor="#496EE5" />
          <stop offset="1" stopColor="#6589FE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_15_245"
          x1="20.718"
          y1="23.7209"
          x2="18.6065"
          y2="35.7661"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#511DAB" />
          <stop offset="1" stopColor="#965BFE" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_15_245"
          x1="10.5445"
          y1="15.2881"
          x2="17.648"
          y2="25.4934"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#511DAB" />
          <stop offset="1" stopColor="#965BFE" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_15_245"
          x1="12.0029"
          y1="26.1891"
          x2="8.43963"
          y2="27.7047"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8B169F" />
          <stop offset="1" stopColor="#D64DEE" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_15_245"
          x1="4.43357"
          y1="21.8308"
          x2="7.7939"
          y2="28.9296"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8B169F" />
          <stop offset="1" stopColor="#D64DEE" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default TezpayLogo;
