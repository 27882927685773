import {
  Box,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import { MerchantsTypes } from "../../utils/services/type/merchants.types";
import { WidgetList } from "../../utils/services/type/widgetList.types";
import { useSelectableMerchant } from "./useSelectableMerchant";
import { pageInfo } from "../../pages/Merchant/Merchant.sx";
import TextboxMerchant from "../TextboxMerchant";
import DatePickerMerchant from "../DatePickerMerchant";

interface Props {
  merchant: MerchantsTypes | undefined;
  data: WidgetList;
  handleUpdatedState: (
    widgetId: string | undefined,
    value: string | undefined,
    flag?: boolean,
    defaultValue?: { widgetId: string; value: string }[]
  ) => void;
  errorType: boolean;
}

const SelectableMerchant = (props: Props) => {
  const { merchant, data, errorType, handleUpdatedState } = props;

  const {
    label,
    prefix,
    setPrefix,
    functionDefaultValue,
    handleChangeLabel,
    handleChangePrefix,
  } = useSelectableMerchant({ data, handleUpdatedState });

  return (
    <>
      <Typography sx={pageInfo}>{data?.displayName}</Typography>
      <FormGroup>
        <FormControl sx={{ mb: "12px" }}>
          <InputLabel id="select-type">Select Type</InputLabel>
          <Select
            labelId="select-type"
            label={"Select Type"}
            value={label}
            onChange={handleChangeLabel}
            sx={{ width: "100%" }}
          >
            {data?.selectableWidgetList.map((item, index) => (
              <MenuItem
                key={item.id}
                value={index}
                onClick={() => {
                  setPrefix(0);
                  if (item.widgetList.length !== 0) {
                    functionDefaultValue(index, false);
                  } else {
                    handleUpdatedState(
                      data.id,
                      item.id,
                      item.widgetList.length !== 0
                    );
                  }
                }}
              >
                {item.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {label !== undefined && (
          <>
            {data?.selectableWidgetList[label].widgetList.map((item) =>
              item?.selectableWidgetList !== undefined ? (
                <Box key={item.id} sx={{ mb: "12px" }}>
                  <Typography sx={{ mb: "12px" }}>
                    {item.displayName}
                  </Typography>
                  <FormControl fullWidth>
                    <InputLabel id="select-prefix">Select Prefix</InputLabel>
                    <Select
                      labelId="select-prefix"
                      label={"Select Prefix"}
                      value={prefix === undefined ? "" : prefix}
                      onChange={handleChangePrefix}
                      sx={{ width: "100%" }}
                    >
                      {item?.selectableWidgetList.map((prefix, index) => (
                        <MenuItem
                          key={prefix.name}
                          value={index}
                          onClick={() => handleUpdatedState(item.id, prefix.id)}
                        >
                          {prefix.displayName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              ) : item.widgetType === "datePicker" ? (
                <DatePickerMerchant
                  handleChangeInput={handleUpdatedState}
                  data={item}
                  key={item.id}
                  errorType={errorType}
                />
              ) : (
                item?.widgetType === "textbox" && (
                  <TextboxMerchant
                    key={item.id}
                    merchant={merchant}
                    type={item.dataType}
                    regEx={item.regex}
                    data={item}
                    errorType={errorType}
                    handleChangeInput={handleUpdatedState}
                  />
                )
              )
            )}
          </>
        )}
      </FormGroup>
    </>
  );
};

export default SelectableMerchant;
