import { httpClient } from "../../api/http/http-client";
import { useQuery } from "react-query";
import { PostParamsTypes } from "./type/postParams.types";
import { MerchantsTypes } from "./type/merchants.types";

export const merchantService = {
  getMerchant: async (data: PostParamsTypes): Promise<MerchantsTypes> => {
    return await httpClient.post<void, MerchantsTypes>("/", data);
  },
};

export const useGetMerchant = (data: PostParamsTypes) => {
  const {
    isLoading: isMerchantLoading,
    data: merchant,
    refetch: refetchMerchant,
  } = useQuery(["merchant", data], () => merchantService.getMerchant(data));

  return { isMerchantLoading, merchant, refetchMerchant };
};
