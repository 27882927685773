import { httpClient } from "../../api/http/http-client";
import { useQuery } from "react-query";
import { PostParamsTypes } from "./type/postParams.types";
import { SubCategories } from "./type/subCategory.types";

export const subCategoryService = {
  getAllCategories: async (data: PostParamsTypes): Promise<SubCategories> => {
    return await httpClient.post<void, SubCategories>("/", data);
  },

  getCategory: async (data: PostParamsTypes): Promise<SubCategories> => {
    return await httpClient.post<void, SubCategories>("/", data);
  },
};

export const useGetSubCategoryList = (data: PostParamsTypes) => {
  const { isLoading: isCategoriesLoading, data: categories } = useQuery(
    ["category", data],
    () => subCategoryService.getAllCategories(data)
  );

  return { isCategoriesLoading, categories };
};

export const useSubGetCategory = (data: PostParamsTypes) => {
  const { isLoading: isCategoryLoading, data: category } = useQuery(
    ["category", data],
    () => subCategoryService.getCategory(data)
  );

  return { isCategoryLoading, category };
};
