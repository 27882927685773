import React from "react";
import useMerchant from "./useMerchant";
import { Box, Button, Card, Typography } from "@mui/material";
import {
  card,
  text,
  merchantInfo,
  merchantBox,
  headerBox,
} from "./Merchant.sx";
import LoaderSpinner from "../../components/LoaderSpinner";
import SelectableMerchant from "../../components/SelectableMerchant";
import NextPageMerchant from "../../components/NextPageMerchant";
import TextboxMerchant from "../../components/TextboxMerchant";
import ErrorPage from "../../components/ErrorPage";
import LastPageMerchant from "../../components/LastPageMerchant";
import DatePickerMerchant from "../../components/DatePickerMerchant";
import { white } from "../../components/ThemeProvider/colors";
import HintMerhcant from "../../components/HintMerchant/HintMerhcant";
const Merchant = () => {
  const {
    isMerchantLoading,
    merchant,
    isNextPageMerchantLoading,
    nextPageMerchant,
    nextPage,
    complete,
    amount,
    errorType,
    validationError,
    setAmount,
    handleBackButtonClick,
    handleUpdatedState,
    navigateToMerchant,
    navigateToSubCategory,
    handleClickButton,
    handleCompletePayment,
  } = useMerchant();

  if (isMerchantLoading) {
    return <LoaderSpinner spinner={false} />;
  }

  if (
    merchant?.code !== 1000 ||
    (nextPageMerchant !== undefined && !nextPageMerchant?.data)
  ) {
    return (
      <Card sx={card}>
        <Box sx={headerBox}>
          <Typography sx={text} onClick={navigateToSubCategory}>
            {merchant?.data.uiNavigation[0]}
          </Typography>
          <Typography sx={{ ...text, cursor: "text", margin: "0 16px" }}>
            &gt;
          </Typography>
          <Typography sx={text} onClick={navigateToMerchant}>
            {merchant?.data.uiNavigation[1]}
          </Typography>
        </Box>
        <Box sx={{ p: "25px 16px" }}>
          <ErrorPage activityId={merchant?.activityId} />
        </Box>
      </Card>
    );
  }

  return (
    <Card sx={card}>
      <Box sx={headerBox}>
        <Typography sx={text} onClick={navigateToSubCategory}>
          {merchant?.data.uiNavigation[0]}
        </Typography>
        <Typography sx={{ ...text, cursor: "text", margin: "0 16px" }}>
          &gt;
        </Typography>
        <Typography sx={text} onClick={navigateToMerchant}>
          {merchant?.data.uiNavigation[1]}
        </Typography>
      </Box>
      <Box sx={merchantBox(isNextPageMerchantLoading)}>
        <Box sx={merchantInfo}>
          <form onSubmit={(e) => handleClickButton(e)}>
            {merchant?.data.widgetList.map((item) =>
              item.widgetType === "combobox" && !nextPage && errorType ? (
                <SelectableMerchant
                  key={item.id}
                  merchant={merchant}
                  data={item}
                  errorType={validationError}
                  handleUpdatedState={handleUpdatedState}
                />
              ) : item.widgetType === "datePicker" && !nextPage && errorType ? (
                <DatePickerMerchant
                  handleChangeInput={handleUpdatedState}
                  data={item}
                  key={item.id}
                />
              ) : (
                item.widgetType === "textbox" &&
                !nextPage &&
                errorType && (
                  <TextboxMerchant
                    key={item.id}
                    regEx={item.regex}
                    merchant={merchant}
                    data={item}
                    type={item.dataType}
                    handleChangeInput={handleUpdatedState}
                    errorType={validationError}
                  />
                )
              )
            )}
            {!nextPage && errorType && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                {/*<Button variant="text" onClick={handleBackButtonClick}>*/}
                {/*  {merchant?.data.uiDisplayName?.backwardAction}*/}
                {/*</Button>*/}
                <Button
                  sx={{
                    width: "100%",
                    backgroundColor: "#3766FF",
                    color: white,
                    outlinedPrimary: {
                      "&:hover": {
                        backgroundColor: "#3766FF",
                      },
                    },
                  }}
                  type={"submit"}
                >
                  {merchant?.data.uiDisplayName?.forwardAction}
                  <div style={{ marginLeft: "5px" }}>
                    <svg
                      width="13"
                      height="12"
                      viewBox="0 0 13 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.15519 6.507L10.4068 6.507L6.86399 10.198C6.81986 10.244 6.78445 10.299 6.75976 10.3599C6.73508 10.4208 6.72161 10.4865 6.72012 10.5531C6.71864 10.6198 6.72917 10.686 6.7511 10.7482C6.77304 10.8104 6.80596 10.8672 6.84799 10.9155C6.89001 10.9638 6.94031 11.0025 6.99602 11.0295C7.05173 11.0565 7.11176 11.0712 7.17267 11.0728C7.23359 11.0745 7.2942 11.063 7.35105 11.039C7.40789 11.015 7.45986 10.979 7.50399 10.933L11.547 6.718C11.6343 6.62323 11.7036 6.51038 11.7507 6.38604C11.7978 6.26169 11.8218 6.12834 11.8213 5.99374C11.8208 5.85915 11.7958 5.72601 11.7477 5.6021C11.6997 5.47818 11.6296 5.36597 11.5415 5.272L7.50307 1.072C7.45901 1.02604 7.4071 0.990016 7.35031 0.965996C7.29352 0.941976 7.23295 0.930425 7.17208 0.932003C7.11121 0.933581 7.05122 0.948259 6.99553 0.975197C6.93984 1.00213 6.88955 1.0408 6.84753 1.089C6.80551 1.13719 6.77257 1.19397 6.75061 1.25609C6.72865 1.3182 6.71809 1.38444 6.71953 1.45102C6.72098 1.5176 6.7344 1.58322 6.75902 1.64412C6.78365 1.70503 6.81901 1.76004 6.86307 1.806L10.4215 5.493L1.15519 5.493C1.03225 5.493 0.914342 5.54641 0.827411 5.64149C0.740479 5.73658 0.691643 5.86553 0.691643 6C0.691643 6.13446 0.740479 6.26342 0.827411 6.3585C0.914342 6.45358 1.03225 6.507 1.15519 6.507Z"
                        fill="white"
                        stroke="white"
                        strokeWidth="0.6"
                      />
                    </svg>
                  </div>
                </Button>
              </Box>
            )}
          </form>
          {nextPage &&
            !errorType &&
            nextPageMerchant !== undefined &&
            nextPageMerchant.data.nextStepId && (
              <NextPageMerchant
                isNextPageInMerchantLoading={isNextPageMerchantLoading}
                nextPageInMerchant={nextPageMerchant}
                complete={complete}
                amount={amount}
                setAmount={setAmount}
                handleCompletePayment={handleCompletePayment}
                handleBackButtonClick={handleBackButtonClick}
              />
            )}
          {nextPage && !errorType && !nextPageMerchant?.data.nextStepId && (
            <LastPageMerchant
              isNextPageMerchantLoading={isNextPageMerchantLoading}
              complete={complete}
              amount={amount}
              setAmount={setAmount}
              nextPageMerchant={nextPageMerchant}
              handleCompletePayment={handleCompletePayment}
              handleBackButtonClick={handleBackButtonClick}
            />
          )}
        </Box>
        {merchant.data.hint != undefined ? (
          <HintMerhcant data={merchant.data}></HintMerhcant>
        ) : null}
      </Box>
    </Card>
  );
};

export default Merchant;
