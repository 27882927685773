import { StyledEngineProvider } from "@mui/material";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import { ThemeProvider } from "./components/ThemeProvider";
import { ROUTES } from "./constants/routes";
import Category from "./pages/Category";
import SubCategory from "./pages/SubCategory";
import { QueryClient, QueryClientProvider } from "react-query";
import Merchant from "./pages/Merchant";
import "./App.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <StyledEngineProvider injectFirst>
          <BrowserRouter>
            <Routes>
              <Route element={<Layout />}>
                <Route path={ROUTES.root} element={<Category />} />
                <Route path={ROUTES.subcategory} element={<SubCategory />} />
                <Route path={ROUTES.merchant} element={<Merchant />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </StyledEngineProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
