import { useGetMerchant } from "../../utils/services/merchantService";
import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { useGetNextPageMerchant } from "../../utils/services/nextPageMerchant";
import { useCompletePayment } from "../../utils/services/completePaymentService";
import useDeviceInfo from "../../constants/useDeviceInfo";

const useMerchant = () => {
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const merchantName = pathname.split("/")[4];
  const subCategory = pathname.split("/")[3];
  const [dataToSend, setDataToSend] = useState<
    { widgetId: string | undefined; value: string | undefined }[]
  >([]);
  const [nextPage, setNextPage] = useState(false);
  const [amount, setAmount] = useState<number | null>();
  const [error, setError] = useState<boolean>(false);
  const [errorType, setErrorType] = useState<boolean>(true);
  const [validationError, setValidationError] = useState<boolean>(false);

  const deviceInfo = useDeviceInfo();

  const lang = localStorage.getItem("lang") || "";

  const { isMerchantLoading, merchant, refetchMerchant } = useGetMerchant({
    action: "mob.tezpay.getStepUi",
    merchantName: merchantName,
    sourceId: "52ebbdee-3981-4a58-a00e-fa5bbd5253a7",
    lang: lang,
    deviceInfo: deviceInfo,
  });

  const activityId = merchant?.activityId;

  const { isNextPageMerchantLoading, nextPageMerchant, refetchNextPage } =
    useGetNextPageMerchant(
      {
        action: "mob.tezpay.processStep",
        stepId: merchant?.data?.stepId,
        sourceId: "52ebbdee-3981-4a58-a00e-fa5bbd5253a7",
        lang: lang,
        widgetList: dataToSend,
        deviceInfo: deviceInfo,
      },
      activityId
    );

  const handleClickButton = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (merchant?.data.nextStepId && !error) {
      setNextPage(true);
    }

    setErrorType(error);
    setValidationError(error);

    if (!error) {
      refetchNextPage();
    }
  };

  const { isCompletetLoading, complete, refetchComplete } = useCompletePayment(
    {
      action: "mob.tezpay.processPayment",
      amount: amount,
      deviceInfo: deviceInfo,
    },
    activityId
  );

  const handleCompletePayment = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    refetchComplete();
  };

  const navigateToSubCategory = () => {
    navigate(
      `${ROUTES.subcategory
        .replace(":lang", lang)
        .replace(":subCategory", subCategory)}`
    );
  };

  const navigateToMerchant = () => {
    navigate(
      `${ROUTES.merchant
        .replace(":lang", lang)
        .replace(":subCategory", subCategory)
        .replace(":merchant", merchantName)}`
    );

    setNextPage(false);
    setErrorType(true);

    refetchMerchant();
  };

  const handleUpdatedState = (
    widgetId: string | undefined,
    value: string | undefined,
    flag?: boolean,
    defaultValue?: { widgetId: string; value: string }[] | undefined,
    regEx?: string
  ) => {
    if (flag) {
      setDataToSend([{ widgetId: widgetId, value: value }]);
    } else if (defaultValue) {
      setDataToSend(defaultValue);
    } else {
      if (regEx) {
        let regexp = new RegExp(regEx);
        setValidationError(false);

        if (value !== undefined && !regexp.test(value)) {
          setError(true);
        }

        if (value !== undefined && regexp.test(value)) {
          setError(false);
        }
      }
      const updatedState = dataToSend
        .map(({ widgetId }) => widgetId)
        .includes(widgetId)
        ? dataToSend.map((obj) => {
            if (obj.widgetId === widgetId) {
              return { ...obj, value: value };
            } else {
              return obj;
            }
          })
        : [...dataToSend, { widgetId: widgetId, value: value }];

      setDataToSend(updatedState);
    }
  };

  const handleBackButtonClick = () => {
    setNextPage(false);
  };

  return {
    isMerchantLoading,
    merchant,
    amount,
    dataToSend,
    nextPage,
    isNextPageMerchantLoading,
    nextPageMerchant,
    isCompletetLoading,
    complete,
    errorType,
    validationError,
    setAmount,
    handleClickButton,
    handleUpdatedState,
    setDataToSend,
    navigateToSubCategory,
    navigateToMerchant,
    handleBackButtonClick,
    handleCompletePayment,
  };
};

export default useMerchant;
