import { SxProps } from "@mui/material";

export const nextPageButton: SxProps = {
  width: "366px",
  backgroundColor: "#3766FF",
  color: "#FFFFFF",
  "&:hover": {
    color: "#3766FF",
  },
};
