import { Card, Box, Typography, IconButton } from "@mui/material";
import React from "react";
import {
  card,
  text,
  categoryBox,
  iconBox,
  iconButton,
  categoryText,
} from "./Category.sx";
import useCategory from "./useCategory";
import LoaderSpinner from "../../components/LoaderSpinner/LoaderSpinner";
import { useOutletContext } from "react-router-dom";
import { Categories } from "../../utils/services/type/categories.types";

const Category = () => {
  const [isCategoriesLoading, categories] =
    useOutletContext<[boolean, Categories | undefined]>();
  const { navigateToCategory } = useCategory();

  if (isCategoriesLoading) {
    return <LoaderSpinner spinner={false} />;
  }

  return (
    <Card sx={card}>
      <Box sx={{ borderBottom: "1px solid #E3E9F3" }}>
        <Typography sx={text}>
          {categories?.data?.uiDisplayName?.categoryTitle}
        </Typography>
      </Box>
      <Box sx={categoryBox}>
        {categories?.data?.categoryList?.map((category) => (
          <Box key={category.name} sx={iconBox}>
            <IconButton
              sx={iconButton}
              onClick={() => navigateToCategory(category.name)}
            >
              <img src={category.icon} alt={"smth"} />
            </IconButton>
            <Typography title={category.displayName} sx={categoryText}>
              {category.displayName}
            </Typography>
          </Box>
        ))}
      </Box>
    </Card>
  );
};

export default Category;
