import { SxProps } from "@mui/material";
import { white } from "../ThemeProvider/colors";

export const innerBox: SxProps = {
  background: "#F6F6F8",
  maxHeight: "calc(100vh - 80px)",
  minHeight: "calc(100vh - 80px)",
  display: "flex",
  "@media screen and (min-width: 320px) and (max-width: 576px)": {
    display: "flex",
    flexDirection:"column",
    overflow: "auto",
  },
};

export const menuListBox: SxProps = {
  background: white,
  minWidth: "20vw",
  maxWidth: "20vw",
  width: "20vw",
  height: "calc(100vh - 80px)",
  overflow: "auto",

  "@media screen and (min-width: 320px) and (max-width: 576px)": {
    display: "none",
  },

  "@media screen and (min-width: 577px) and (max-width: 768px)": {
    display: "none",
  },

  "@media screen and (min-width: 768px) and (max-width: 992px)": {
    display: "none",
  },
};
