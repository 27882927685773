import { SxProps } from "@mui/material";
import { CSSProperties } from "react";
import { white, black2, grey5 } from "../../ThemeProvider/colors";

export const header: SxProps = {
  width: "100%",
  height: "80px",
  background: white,
  padding: "23px 36px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  "@media screen and (min-width: 320px) and (max-width: 576px)": {
    width: "90vw",
    padding: "23px 0",

  },
};

export const menuIcon: SxProps = {
  display: "none",

  "@media screen and (min-width: 320px) and (max-width: 992px)": {
    display: "block",

    "&:hover": {
      background: "transparent",
    },
  },
};

export const storeButtonsBox: SxProps = {
  display: "flex",
  gap: "10px",
  marginRight: "30px",
  "&:hover":{
    backgroundcolor: 'transparent'
  },
  "@media screen and (min-width: 320px) and (max-width: 576px)": {
    display: "none",
  },
};

export const callNumberIcon: SxProps = {
  display: "block",

  "@media screen and (min-width: 320px) and (max-width: 576px)": {
    display: "none",
  },
};

export const lefSideMenu: SxProps = {
  background: white,
  overflow: "auto",
  width: "100vw",
  display: "none",

  "&::-webkit-scrollbar": {
    width: "5px",
  },

  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
  },

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,.1)",
  },

  "@media screen and (min-width: 320px) and (max-width: 576px)": {
    display: "block",
  },

  "@media screen and (min-width: 577px) and (max-width: 768px)": {
    display: "block",
  },

  "@media screen and (min-width: 768px) and (max-width: 992px)": {
    display: "block",
  },
};

const css: CSSProperties = {
  display: "flex",
  alignItems: "center",
  width: "100%",
  padding: "5px 25px",
  fontSize: "14px",
  lineHeight: "1.57",
  fontWeight: "500",
  color: black2,
  textDecoration: "none",
};

export const style: Record<string, CSSProperties> = {
  link: {
    ...css,
  },

  activeLink: {
    ...css,
    background: grey5,
  },
};
