import { Box, List, ListItem } from "@mui/material";
import { lefSideMenu, style } from "./MenuList.sx";
import { NavLink } from "react-router-dom";
import React from "react";
import { Categories } from "../../utils/services/type/categories.types";

interface Props {
  categories: Categories | undefined;
}

const MenuList = (props: Props) => {
  const { categories } = props;
  const lang = localStorage.getItem("lang") || "";

  return (
    <List sx={lefSideMenu}>
      {categories?.data.categoryList.map((category) => (
        <ListItem key={category.name} sx={{ pl: 0, pr: 0 }}>
          <NavLink
            to={`${lang}/category/${category.name}`}
            style={({ isActive }) => (isActive ? style.activeLink : style.link)}
          >
            <Box
              sx={{
                width: "28px",
                height: "28px",
                background: "#fff",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "20px",
              }}
            >
              <img
                src={category.menuIcon}
                alt={"smth"}
                width={"20px"}
                height={"20px"}
              />
            </Box>
            {category.displayName}
          </NavLink>
        </ListItem>
      ))}
    </List>
  );
};

export default MenuList;
