import { Box, Typography, IconButton, Card } from "@mui/material";
import React from "react";
import {
  card,
  text,
  categoryBox,
  iconBox,
  iconButton,
  categoryText,
} from "./SubCategory.sx";
import useSubCategory from "./useSubCategory";
import LoaderSpinner from "../../components/LoaderSpinner";
import ErrorPage from "../../components/ErrorPage";

const SubCategory = () => {
  const { isCategoryLoading, category, navigateToCategory } = useSubCategory();
  const lengthOfData =
    category?.data.merchantList.length !== undefined &&
    category?.data.merchantList.length > 6;

  if (isCategoryLoading) {
    return <LoaderSpinner spinner={false} />;
  }

  if (category?.code !== 1000 || category?.data.merchantList.length === 0) {
    return (
      <Card sx={card}>
        <Box sx={{ borderBottom: "1px solid #E3E9F3" }}>
          <Typography sx={text}>{category?.data.uiNavigation[0]}</Typography>
        </Box>
        <Box sx={{ p: "25px 16px" }}>
          <ErrorPage activityId={category?.activityId} />
        </Box>
      </Card>
    );
  }

  return (
    <Card sx={card}>
      <Box sx={{ borderBottom: "1px solid #E3E9F3" }}>
        <Typography sx={text}>{category?.data.uiNavigation[0]}</Typography>
      </Box>

      <Box sx={categoryBox(lengthOfData)}>
        {category?.data.merchantList.map((item) => (
          <Box key={item.name} sx={iconBox}>
            <IconButton
              sx={iconButton}
              onClick={() => navigateToCategory(`${item.name}`)}
            >
              <img src={item.icon} alt={item.displayName} />
            </IconButton>
            <Typography title={item.displayName} sx={categoryText}>
              {item.displayName}
            </Typography>
          </Box>
        ))}
      </Box>
    </Card>
  );
};

export default SubCategory;
