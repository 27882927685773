import * as React from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

const useLanguageSelector = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMobile = useMediaQuery("(max-width: 478px)");
  const [currentLang, setCurrentLang] = useState<string | null>("");

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const lang = pathname.split("/")[1];
  const url = pathname.slice(3);

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (lang === "az") {
      localStorage.setItem("lang", "az");
      setCurrentLang(isMobile ? "Az" : "Azərbaycan");
    } else if (lang === "ru") {
      localStorage.setItem("lang", "ru");
      setCurrentLang(isMobile ? "Ru" : "Русский");
    } else if (lang === "en") {
      localStorage.setItem("lang", "en");
      setCurrentLang(isMobile ? "En" : "English");
    }
  }, [lang, isMobile]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLang = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const newLang = e.currentTarget.textContent;
    setCurrentLang(newLang);
    setAnchorEl(null);

    if (newLang === "Azərbaycan" || newLang === "Az") {
      localStorage.setItem("lang", "az");
      navigate("az" + url);
    }

    if (newLang === "Русский" || newLang === "Ru") {
      localStorage.setItem("lang", "ru");
      navigate("ru" + url);
    }

    if (newLang === "English" || newLang === "En") {
      localStorage.setItem("lang", "en");
      navigate("en" + url);
    }
  };

  return {
    open,
    handleClick,
    anchorEl,
    currentLang,
    isMobile,
    handleClose,
    handleChangeLang,
  };
};

export default useLanguageSelector;
