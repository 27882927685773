import {Box, useMediaQuery} from "@mui/material";
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { innerBox, menuListBox } from "./Layout.sx";
import LayoutHeader from "./LayoutHeader";
import { useGetCategoryList } from "../../utils/services/categoryService";
import MenuList from "../MenuList";
import useDeviceInfo from "../../constants/useDeviceInfo";
import MobileLayoutHeader from "./LayoutHeader/MobileLayoutHeader";
const Layout = () => {
  const { pathname } = useLocation();
  const deviceInfo = useDeviceInfo();
  const categoryStart = pathname.split("/")[2].startsWith("category");
  const categoryEnd = pathname.endsWith("/category");
  const categoryEndWithSlash = pathname.endsWith("/category/");
  const lang = localStorage.getItem("lang") || "";
  const isMobile = useMediaQuery("(max-width: 578px)");

  const { isCategoriesLoading, categories } = useGetCategoryList({
    action: "mob.tezpay.getCategoryUi",
    sourceId: "52ebbdee-3981-4a58-a00e-fa5bbd5253a7",
    lang: lang,
    deviceInfo: deviceInfo,
  });

  return (
    <Box>
      <LayoutHeader categoryStart={categoryStart} categoryEnd={categoryEnd} categoryEndWithSlash={categoryEndWithSlash} categories={categories}/>
      <Box sx={innerBox}>
        {categoryStart && !categoryEnd && !categoryEndWithSlash && (
          <Box sx={menuListBox}>
            <MenuList categories={categories} />
          </Box>
        )}
          { isMobile
              && (
                  <MobileLayoutHeader></MobileLayoutHeader>
              )}
          <Outlet context={[isCategoriesLoading, categories]} />
      </Box>
    </Box>
  );
};

export default Layout;
