import React, { useState } from "react";
import {HintTypes} from "../../utils/services/type/hint.types";
import {hintMerchantSx} from "./HintMerchant.sx";
import {Box} from "@mui/material";


interface Props {
 data : HintTypes;
}

const HintMerhcant = (props: Props) => {
    const {data} = props;
    // @ts-ignore
    return (
        <Box sx={hintMerchantSx} className={"flex-container"}>
            <div style={{
                marginRight:"10px",
            }}><svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 1.75C7.24535 1.75 1.75 7.24542 1.75 14.0001C1.75 20.7547 7.24535 26.25 14 26.25C20.7547 26.25 26.25 20.7547 26.25 14.0001C26.25 7.24542 20.7547 1.75 14 1.75ZM14 24.0227C8.47339 24.0227 3.97727 19.5266 3.97727 14.0001C3.97727 8.47354 8.47339 3.97727 14 3.97727C19.5266 3.97727 24.0227 8.47354 24.0227 14.0001C24.0227 19.5266 19.5265 24.0227 14 24.0227Z" fill="#FFAA00"/>
                <path d="M14.0002 6.94696C13.1816 6.94696 12.5156 7.61336 12.5156 8.43248C12.5156 9.25085 13.1816 9.91666 14.0002 9.91666C14.8188 9.91666 15.4847 9.25085 15.4847 8.43248C15.4847 7.61336 14.8188 6.94696 14.0002 6.94696Z" fill="#FFAA00"/>
                <path d="M13.9999 12.1439C13.3848 12.1439 12.8862 12.6425 12.8862 13.2576V19.9394C12.8862 20.5544 13.3848 21.053 13.9999 21.053C14.6149 21.053 15.1135 20.5544 15.1135 19.9394V13.2576C15.1135 12.6425 14.6149 12.1439 13.9999 12.1439Z" fill="#FFAA00"/>
            </svg></div>
            <div>{data.hint}</div>
        </Box>
    );
};

export default HintMerhcant;