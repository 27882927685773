import { useSubGetCategory } from "../../utils/services/subCategoryService";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import useDeviceInfo from "../../constants/useDeviceInfo";

const useSubCategory = () => {
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const subCategory = pathname.split("/")[3];
  const lang = localStorage.getItem("lang") || "";
  const deviceInfo = useDeviceInfo();

  const navigateToCategory = (merchant: string) => {
    navigate(
      `${ROUTES.merchant
        .replace(":lang", lang)
        .replace(":subCategory", subCategory)
        .replace(":merchant", merchant)}`
    );
  };

  const { isCategoryLoading, category } = useSubGetCategory({
    action: "mob.tezpay.getMerchantUi",
    categoryId: "",
    categoryName: subCategory,
    sourceId: "52ebbdee-3981-4a58-a00e-fa5bbd5253a7",
    lang: lang,
    deviceInfo: deviceInfo,
  });

  return { isCategoryLoading, category, navigateToCategory };
};

export default useSubCategory;
