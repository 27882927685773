import { Box, MenuItem, Select, Typography } from "@mui/material";
import React from "react";
import { WidgetList } from "../../utils/services/type/widgetList.types";
import useChoiceListMerchant from "./useChoiceListMerchant";

interface Props {
  data: WidgetList;
  // handleUpdatedState: (
  //   widgetId: string | undefined,
  //   value: string | undefined,
  //   flag?: boolean,
  //   defaultValue?: { widgetId: string; value: string }[]
  // ) => void;
  handleUpdatedState: (e: any) => void;
}

const ChoiceListMerchant = (props: Props) => {
  const { data, handleUpdatedState } = props;
  const { label, handleChangeLabel } = useChoiceListMerchant();

  return (
    <Box sx={{ width: "100%" }}>
      <Typography sx={{ mb: "12px", mt: "12px" }}>
        {data.displayName}
      </Typography>

      <Select
        value={label}
        onChange={(e) => handleChangeLabel(e)}
        sx={{ width: "100%" }}
      >
        {data.choiceList?.map((choice, index) => (
          <MenuItem
            key={choice.key}
            value={index}
            onClick={() => {
              handleUpdatedState(choice.key);
            }}
          >
            <Typography>{choice.displayName}</Typography>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default ChoiceListMerchant;
